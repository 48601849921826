import { Link, withPrefix } from 'gatsby'
import React from 'react'
import { Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'

import {
  Button,
  CheckboxWrapper,
  Form,
  FormBottomWrapper,
} from '../../elements/forms'
import { Checkbox } from '../../forms/input'
import { Regulations } from './regulations'
import { TosWrapper } from './step2'

export const Step3Form = ({ isLoading, onSubmit }) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      tos4: false,
    },
  })

  return (
    <TosWrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormBottomWrapper xs={12}>
          <Row>
            <Col xs={12} md={12}>
              <Row>
                <CheckboxWrapper xs={12}>
                  <Regulations />
                </CheckboxWrapper>
                <CheckboxWrapper xs={12}>
                  <Checkbox
                    {...register('tos4', { required: true })}
                    control={control}
                    label={
                      <>
                        Oświadczam,że zapoznałam/zapoznałem się z treścią
                        Regulaminu programu Misja Kieszonkowe oraz akceptuję
                        jego treść, co jest równoznaczne, ze zgłoszeniem
                        wskazanej w formularzu Placówki do udziału w Programie
                        Misja Kieszonkowe i wskazaniem Klasy, która ma brać
                        udział w tym Programie (
                        <a
                          href={withPrefix(
                            '/docs/MK2024-regulamin-programu-Misja-Kieszonkowe.pdf'
                          )}
                          target="_blank"
                        >
                          Pobierz regulamin
                        </a>
                        ).
                      </>
                    }
                    error={errors.tos4}
                  />
                </CheckboxWrapper>
                <CheckboxWrapper xs={12}>
                  <Button as="button" className="btn" disabled={isLoading}>
                    {isLoading && <>Wysyłanie zgłoszenia</>}
                    {!isLoading && <>Wyślij zgłoszenie</>}
                  </Button>
                </CheckboxWrapper>
              </Row>
            </Col>
          </Row>
        </FormBottomWrapper>
      </Form>
    </TosWrapper>
  )
}
