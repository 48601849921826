import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { useId } from 'react-use-id-hook'
import styled from 'styled-components'
import Select from 'react-select'

import { theme } from '../layout'

import arrowSelectIcon from '../../images/arrow_select_green.svg'

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: `#00975F`,
    boxShadow: state.isFocused
      ? `0 0 0 0.2rem ${theme.colorGreenBright}`
      : `none`,
  }),
  indicatorSeparator: () => ({ display: `none` }),
  menu: (provided) => ({ ...provided, marginTop: `3px` }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused
      ? theme.colorGreenBright
      : state.isSelected
        ? `rgba(145, 189, 36, 0.21)`
        : `#fff`,
    color: state.isFocused ? `#fff` : `#000`,
    fontWeight: `400`,
    transition: `ease .2s all`,
  }),
}

const SelectWrapper = styled.div`
  position: relative;
  margin-bottom: 0.75rem;
  z-index: 10;
  label {
    ${(props) =>
    props.error &&
    `
      color: ${props.theme.colorRed};
    `}
  }

  .select-outer {
    & > div {
      ${(props) =>
    props.error &&
    `
        border-color: ${props.theme.colorRed};
      `}
    }
    div {
      &[class*='indicatorContainer'] {
        background-image: url(${arrowSelectIcon});
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 35%;
        svg {
          visibility: hidden;
        }
      }
    }
  }
`

const SelectOuter = ({
  name,
  options,
  control,
  label,
  labelId,
  error,
  isLoading,
  disabled,
}) => {
  const id = useId()
  labelId = labelId ? labelId : id

  return (
    <SelectWrapper error={error}>
      {label && <label htmlFor={labelId}>{label}</label>}

      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <Select
              options={options}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              id={id}
              styles={customStyles}
              className="select-outer"
              isLoading={isLoading}
              loadingMessage={() => 'Pobieranie'}
              noOptionsMessage={() => 'Brak rezultatów'}
              placeholder=""
              isSearchable={disabled ? false : true}
            />
          )
        }}
      />
    </SelectWrapper>
  )
}

export const CitySelect = ({ label, name, control, region, error }) => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!region) {
      setOptions([])
      return
    }

    setIsLoading(true)
    axios
      .post(process.env.GATSBY_MK_API_SEARCH_SCHOOLS_URL, { region: region })
      .then((resp) => {
        setOptions(resp.data.map((d) => ({ label: d, value: d })))
        setIsLoading(false)
      })
  }, [region])

  return (
    <SelectOuter
      label={label}
      error={error}
      name={name}
      control={control}
      options={options}
      isLoading={isLoading}
    />
  )
}

export const GenericSelect = ({ label, name, control, options, error, disabled }) => {
  return (
    <SelectOuter
      label={label}
      error={error}
      name={name}
      control={control}
      options={options}
      disabled={disabled}
    />
  )
}

export const RegionSelect = ({ label, name, control, error }) => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    axios
      .post(process.env.GATSBY_MK_API_SEARCH_SCHOOLS_URL, {})
      .then((resp) => {
        setOptions(resp.data.map((d) => ({ label: d, value: d })))
        setIsLoading(false)
      })
  }, [])

  return (
    <SelectOuter
      label={label}
      error={error}
      name={name}
      control={control}
      options={options}
      isLoading={isLoading}
    />
  )
}

export const SchoolSelect = ({ label, name, control, region, city, error }) => {
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!region || !city) {
      setOptions([])
      return
    }

    setIsLoading(true)
    axios
      .post(process.env.GATSBY_MK_API_SEARCH_SCHOOLS_URL, {
        region: region,
        city: city,
      })
      .then((resp) => {
        setOptions(resp.data.map((d) => ({ label: d.name, value: d.id })))
        setIsLoading(false)
      })
  }, [region, city])

  return (
    <SelectOuter
      label={label}
      error={error}
      name={name}
      control={control}
      options={options}
      isLoading={isLoading}
    />
  )
}
