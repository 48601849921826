import React, { useState } from 'react'
import styled from 'styled-components'
import { Popover, PopoverBody } from 'reactstrap'

import infoIcon from '../../images/info_green_icon.svg'

const PopoverInfoWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  width: 25px;
  height: 25px;
  background-image: url(${infoIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transform: translateY(-25%);
  z-index: 30;
  cursor: pointer;
  @media (min-width: 992px) {
    display: none;
  }
`

const PopoverInfoBody = styled(PopoverBody)`
  background-color: ${(props) => props.theme.colorGreenLight};
  color: ${(props) => props.theme.colorWhite};
  font-weight: 700;
  padding: 15px;
  border-radius: 10px;
`

const PopoverInfoInnerWrapper = styled(Popover)`
  .popover {
    max-width: 280px;
    border-radius: 10px;
    @media (min-width: 576px) and (max-width: 991px) {
      max-width: 480px;
    }
    @media (max-width: 340px) {
      max-width: 250px;
    }
    .popover-inner {
      border-radius: 10px;
    }
    .arrow {
      &:after {
        border-left-color: ${(props) => props.theme.colorGreenLight}!important;
      }
    }
  }
`

const PopoverInfo = (props) => {
  const { idName, text, className } = props
  const [popoverOpen, setPopoverOpen] = useState(false)

  const toggle = () => setPopoverOpen(!popoverOpen)

  return (
    <PopoverInfoWrapper id={`popover${idName}`} className={className}>
      <PopoverInfoInnerWrapper
        placement="left"
        isOpen={popoverOpen}
        target={`popover${idName}`}
        toggle={toggle}
      >
        <PopoverInfoBody dangerouslySetInnerHTML={{ __html: text }} />
      </PopoverInfoInnerWrapper>
    </PopoverInfoWrapper>
  )
}

export default PopoverInfo
