import React from 'react'
import { Controller } from 'react-hook-form'
import { useId } from 'react-use-id-hook'
import styled from 'styled-components'

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 0.75rem;
  z-index: 10;
  label {
    ${(props) =>
    props.error &&
    `
      color: ${props.theme.colorRed};
    `}
  }

  input {
    border-color: ${(props) => props.theme.colorGreenLight};

    &:focus {
      border-color: ${(props) => props.theme.colorGreenBright};
      box-shadow: 0 0 0 0.2rem ${(props) => props.theme.colorGreenBright};
    }

    ${(props) =>
    props.error &&
    `
      border-color: ${props.theme.colorRed};
    `}
  }
`

export const Checkbox = ({ name, id, label, control, error }) => {
  const inputId = useId()
  return (
    <InputWrapper error={error} className="custom-control custom-checkbox">
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <input
              id={id ? id : inputId}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type="checkbox"
              className="custom-control-input"
            />
          )
        }}
      />
      {label && (
        <label htmlFor={inputId} className="custom-control-label">
          {label}
        </label>
      )}
    </InputWrapper>
  )
}

export const Input = ({
  name,
  id,
  label,
  control,
  error,
  type = 'text',
  min,
  max,
  disabled,
}) => {
  const inputId = useId()

  return (
    <InputWrapper error={error} className="form-group">
      {label && <label htmlFor={inputId}>{label}</label>}
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <input
              id={id ? id : inputId}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              type={type}
              className="form-control"
              {...(min && { min: min })}
              {...(max && { max: max })}
              {...(disabled && { disabled: disabled })}
            />
          )
        }}
      />
    </InputWrapper>
  )
}
