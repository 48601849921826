import { Link, withPrefix } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const FormRegulationsOList = styled.ol`
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  a {
    color: ${(props) => props.theme.colorGreenLight};
  }
  li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
    &:before {
      content: counters(item, ".") ". ";
      display: table-cell;
      width: 3em;
    }
    li {margin: 0;
          &:before {
          content: counters(item, ".") ". ";
          }
        }
    }
  }
`

const FormRegulationsOListDeeperLevel = styled.ol`
  margin-left: 1em;
`

const FormRegulationsOListDeeperLevelAlpha = styled.ol`
  margin-left: 1em;
  counter-reset: alpha;
  li {
    &:before {
      counter-increment: alpha;
      content: '(' counter(alpha, lower-alpha) ') ';
    }
  }
`

export const Regulations = () => (
  <>
    <h2>Regulamin Programu „Misja Kieszonkowe”</h2>
    <FormRegulationsOList xs={12}>
      <li>
        <strong>SŁOWNIK POJĘĆ STOSOWANYCH W REGULAMINIE PROGRAMU</strong>
        <FormRegulationsOList>
          <li>
            <strong>Bank/Organizator</strong> – BNP Paribas Bank Polska S.A. z siedzibą w Warszawie (01-211) przy ul. Kasprzaka 2, wpisany do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000011571, NIP: 5261008546, o kapitale zakładowym w wysokości: 147 676 946 zł, w całości wpłaconym.
          </li>
          <li>
            <strong>Biuro Programu</strong> – biuro uruchomione przez Koordynatora Programu na potrzeby organizacji
            Programu, prowadzone pod adresem Garden of Words spółka z ograniczoną
            odpowiedzialnością z siedzibą w Warszawie (01-209) , ul. Hrubieszowska 6A, wpisana
            do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w
            Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS:
            0000973765, NIP: 7011089585, o kapitale zakładowym w wysokości: 6.000 zł., nr tel.:
            519877700, adres e-mail: kontakt@misjakieszonkowe.pl.
          </li>
          <li>
            <strong>Formularz Rejestracyjny</strong> – formularz zamieszczony na Stronie Internetowej Programu, za
            pośrednictwem którego jest dokonywana rejestracja Szkoły do Programu.
          </li>
          <li>
            <strong>Klasa</strong> – ogół uczniów uczęszczających do danego oddziału szkoły na poziomie klasy I, II, III
            lub - odpowiednio - IV-VI, którzy biorą aktywny udział w Programie w imieniu Szkoły. Klasa
            bierze udział w Programie poprzez zgłoszenie udziału Szkoły, która wskazała daną Klasę do
            wykonywania czynności w ramach Programu. Udział Klasy w Programie musi być
            poprzedzony zgłoszeniem się Szkoły, w której jest dana Klasa, do Programu. W ramach jednej
            Szkoły w Programie może uczestniczyć więcej niż jedna Klasa.
          </li>
          <li>
            <strong>Koordynator Programu</strong> – Garden of Words spółka z ograniczoną odpowiedzialnością z
            siedzibą w Warszawie, przy ul. Hrubieszowska 6A, 01-209 Warszawa, wpisana do rejestru
            przedsiębiorców prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII
            Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000973765, NIP:
            7011089585, przeprowadzająca czynności z zakresu realizacji Programu, na zlecenie
            Organizatora.
          </li>
          <li>
            <strong>Materiały Edukacyjne</strong> – przekazywane Opiekunowi lub Wychowawcy Świetlicy materiały
            edukacyjne przygotowane przez metodyków, zgodne z podstawą programową, zgodnie z
            którymi Opiekunowie lub Wychowawcy Świetlicy mogą przeprowadzać z Klasą, lub
            odpowiednio ze Świetlicą, zajęcia.
          </li>
          <li>
            <strong>Opiekun</strong> – pedagog/nauczyciel Klasy, opiekujący się Klasą w Szkole, który uzyskał zgodę
            dyrektora szkoły na udział Klasy w Programie.
          </li>
          <li>
            <strong>Partner MasterCard</strong> – Mastercard Europe SA, belgijska spółka zarejestrowana przez Sąd
            Gospodarczy w Nivelles w Belgii (numer w rejestrze przedsiębiorców: RPR 0448038446), z
            siedzibą pod adresem: 198/A, Chaussée de Tervuren, 1410 Waterloo, Belgia, posiadająca swój
            oddział w Polsce Mastercard Europe Spółka Akcyjna Oddział w Polsce, z siedzibą w
            Warszawie, pod adresem: ul. Plac Europejski 1, 00-844 Warszawa, wpisaną przez Sąd
            Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru
            Sądowego pod nr KRS: 0000345959, wspierająca Organizatora.
          </li>
          <li>
            <strong>Program</strong> - skierowany do Szkół program edukacyjno-naukowy „Misja Kieszonkowe”,
            którego celem jest propagowanie wiedzy na temat finansów oraz bankowości. Udział w
            Programie powinien być poprzedzony rejestracją do Programu dokonaną za pośrednictwem
            Strony Internetowej Programu.
          </li>
          <li>
            <strong>Regulamin Programu</strong> – niniejszy regulamin programu określający zasady uczestnictwa w
            Programie, opublikowany na Stronie Internetowej Programu.
          </li>
          <li>
            <strong>RSPO</strong> – Rejestr Szkół i Placówek Oświatowych dostępny na stronie internetowej pod adresem https://rspo.gov.pl/.
          </li>
          <li>
            <strong>Strona Internetowa Programu</strong> – strona internetowa
            znajdująca się pod adresem https://www.misjakieszonkowe.pl.
          </li>
          <li>
            <strong>Szkoła</strong> – instytucja oświatowo – wychowawcza zapewniająca nauczanie w zakresie ramowych planów nauczania, publiczna lub niepubliczna będąca ośmioletnią szkołą podstawową, znajdująca się na terenie Rzeczypospolitej Polskiej.
          </li>
          <li>
            <strong>Świetlica</strong> – uczniowie uczęszczających do danego oddziału Szkoły na poziomie klasy I, II, III lub - odpowiednio - IV-VI, którzy są zapisani do świetlicy i biorą udział w zorganizowanych zajęciach świetlicowych prowadzonych przez Wychowawcę Świetlicy. Świetlica bierze udział w Programie poprzez zgłoszenie udziału Szkoły, która wskazała daną Świetlicę do wykonywania czynności w ramach Programu. Udział Świetlicy w Programie musi być poprzedzony zgłoszeniem się Szkoły, w której jest prowadzona dana Świetlica, do Programu.
          </li>
          <li>
            <strong>Uczestnik Programu</strong> - Szkoła, która bierze udział w Programie poprzez Klasy lub Świetlicę oraz została zgłoszona do Programu przez upoważnioną (uprawnioną) osobę, za pośrednictwem Strony Internetowej Programu. Szkoła może wziąć udział w Konkursie tyle razy, ile jest Klas i Świetlic w Szkole.
          </li>
          <li>
            <strong>Wychowawca Świetlicy</strong> - nauczyciel wychowawca w świetlicy sprawujący opiekę nad
            Świetlicą, który uzyskał zgodę dyrektora Szkoły na udział Świetlicy w Programie.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>ZAŁOŻENIA PROGRAMU</strong>
        <FormRegulationsOList>
          <li>
            Program jest organizowany przez Organizatora przy współpracy z Koordynatorem Programu, a
            jego celem jest popularyzacja wśród uczniów Szkoły wiedzy nt. finansów i bankowości,
            przedsiębiorczości i poszerzenie ich wiedzy w tych zakresach. Partner MasterCard wspiera
            Organizatora przy realizacji celów Programu.
          </li>
          <li>
            W ramach Programu mogą być organizowane konkursy lub inne akcje edukacyjne dla Klas lub
            Świetlic. Organizator będzie informował Uczestników Programu o wskazanych konkursach
            oraz programach edukacyjnych. Organizator zastrzega sobie prawo organizacji konkursów lub
            innych akcji edukacyjnych, które będą kierowane wyłącznie do Klas albo do Świetlic.
          </li>
          <li>
            Program przeprowadzany jest na terenie Rzeczypospolitej Polskiej.
          </li>
          <li>
            W Programie mogą uczestniczyć jedynie klasy I, II, III i IV-VI szkół podstawowych.
          </li>
          <li>
            Program trwa przez czas nieoznaczony, czas jego trwania rozpoczyna się w dniu 27.10.2022.
          </li>
          <li>
            Organizator po upływie 6 miesięcy od rozpoczęcia Programu może zakończyć funkcjonowanie
            Programu. O zakończeniu Programu Organizator poinformuje Uczestników za pośrednictwem
            korespondencji email, nie później niż na 30 dni przed planowaną datą zakończenia
            funkcjonowania Programu. Zakończenie Programu nie będzie miało wpływu na prawa nabyte
            Uczestników w trakcie jego trwania.
          </li>
          <li>
            Zgłoszenie do Programu oraz uczestnictwo w nim są całkowicie dobrowolne i bezpłatne.
          </li>
          <li>
            Program skierowany jest do wszystkich Szkół w Polsce. Organizator zastrzega sobie prawo do
            informowania o Programie wybranych Opiekunów, wybranych Wychowawców Świetlic oraz
            wybrane Szkoły.
          </li>
          <li>
            W ramach Programu Organizator przekazuje Uczestnikom Programu, Opiekunowi lub
            Wychowawcy Świetlicy Materiały Edukacyjne, które pozwalają na przeprowadzenie zajęć z
            Klasami.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>UCZESTNICTWO W PROGRAMIE</strong>
        <FormRegulationsOList>
          <li>
            Program ma charakter otwarty i może wziąć w nim udział każda Szkoła.
          </li>
          <li>
            Warunkiem wzięcia udziału w Programie jest:
            <FormRegulationsOList>
              <li>
                Zapoznanie się przez osobę upoważnioną (uprawnioną) do reprezentowania Szkoły z
                Regulaminem Programu,
              </li>
              <li>
                Zaakceptowanie przez osobę upoważnioną (uprawnioną) do reprezentowania Szkoły
                Regulaminu Programu,
              </li>
              <li>
                Uzyskanie zgody dyrektora Szkoły na udział w Programie oraz innych zgód, jeżeli są/
                będą wymagane do wzięcia przez Szkołę udziału w Programie,
              </li>
              <li>
                Zarejestrowanie przez uprawnioną osobę Szkoły do Programu i wskazanie Klasy lub
                Świetlicy, która ma brać udział w Programie za pomocą Formularza Rejestracyjnego na
                Stronie Internetowej Programu.
              </li>
            </FormRegulationsOList>
          </li>
          <li>
            Formularz Rejestracyjny powinien być wypełniony przez osobę upoważnioną (uprawnioną) do
            reprezentowania Szkoły w sposób prawidłowy i kompletny. Osoba ta przesyła Formularz
            Rejestracyjny za pośrednictwem Strony Internetowej. Tylko prawidłowo i kompletnie
            wypełniony Formularz Rejestracyjny umożliwia zakwalifikowanie Szkoły do Programu.
          </li>
          <li>
            Biuro Programu potwierdzi otrzymanie Formularza Rejestracyjnego, za pośrednictwem
            wiadomości email przesłanej na adres wskazany w Formularzu Rejestracyjnym.
          </li>
          <li>
            Organizator zastrzega sobie prawo do weryfikacji Formularza Rejestracyjnego oraz spełniania
            przez Uczestnika Programu warunków Programu i w tym celu ma prawo do żądania informacji,
            danych, wyjaśnień, dokumentów w przypadkach istnienia uzasadnionych wątpliwości w tym
            zakresie, a osoba dokonująca w imieniu Szkoły zgłoszenia jest zobowiązana na żądanie Biura
            Programu zadośćuczynić tym żądaniom w terminie 14 dni od dnia otrzymania wezwania.
            W przypadku nieudzielenia niezbędnych informacji lub przekazania niezbędnych danych,
            Szkoła i wskazana w ramach danego zgłoszenia do udziału Klasa lub Świetlica nie może wziąć
            udziału w Programie.
          </li>
          <li>
            Jedną Klasę może reprezentować tylko jeden Opiekun, wskazany w Formularzu
            Rejestracyjnym.
          </li>
          <li>
            Świetlicę może reprezentować tylko jeden Wychowawca Świetlicy, wskazany w Formularzu
            Rejestracyjnym.
          </li>
          <li>
            Organizatorowi przysługuje uprawnienie do wykluczenia z Programu Klasy lub Świetlicy, w
            przypadku, gdy Klasa lub Świetlica swoim działaniem lub zaniechaniem narusza przepisy
            prawa, dobre obyczaje lub postanowienia Regulaminu Programu, a także gdy działania lub
            zaniechania Klasy lub Świetlicy naruszają dobra osobiste Organizatora lub Koordynatora
            Programu.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>MATERIAŁY EDUKACYJNE</strong>
        <FormRegulationsOList>
          <li>
            Opiekunowie Klas zgłoszonych przez Szkoły do wykonywania czynności w ramach Programu
            oraz Wychowawcy Świetlicy zgłoszeni przez Szkoły do wykonywania czynności w ramach
            Programu, mogą otrzymywać Materiały Edukacyjne na adres email podany w Formularzu
            Rejestracyjnym. Materiały Edukacyjne nie mogą być przekazane innym podmiotom lub
            osobom, niż Klasa lub Świetlica oraz opiekunowie prawni/rodzice uczniów Klasy lub Świetlicy
            zgłoszonej przez Szkołę do udziału w Programie.
          </li>
          <li>
            Szkoły, Opiekunowie oraz Wychowawcy Świetlicy nie są uprawnieni do przekazywania
            Materiałów Edukacyjnych poza placówkę szkolną.
          </li>
          <li>
            Opiekun lub Wychowawca Świetlicy na żądanie opiekunów prawnych lub rodziców uczniów
            Klasy lub Świetlicy jest zobowiązany do przekazania wszelkich informacji dotyczących
            Programu oraz jest uprawniony do przekazania im Materiałów Edukacyjnych.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>KOMUNIKACJA W PROGRAMIE</strong>
        <FormRegulationsOList>
          <li>
            Biuro Programu prowadzi bieżącą komunikację związaną z Programem z Opiekunami,
            Wychowawcami Świetlicy oraz Szkołami, zarówno przed rejestracją do Programu jak i po
            zarejestrowaniu do Programu.
          </li>
          <li>
            Biuro Programu będzie prowadziło ze Szkołami, Opiekunami oraz Wychowawcami Świetlicy
            kontakt, którego celem jest zaproszenie do wzięcia udziału w Programie oraz skorzystania z
            Materiałów Edukacyjnych.
          </li>
          <li>
            Biuro Programu po przesłaniu Formularza Rejestracyjnego będzie prowadziło kontakt, którego
            celem jest informowanie o bieżących działaniach w ramach Programu oraz etapach jego
            realizacji, wskazywanie braków formalnych zgłoszenia do Programu, informowanie o
            organizowanych konkursach lub innych akcjach edukacyjnych.
          </li>
          <li>
            Opiekunowie, Wychowawcy Świetlicy, Uczestnicy Programu, Szkoły mogą kontaktować się
            Biurem Programu za pośrednictwem korespondencji email lub telefonicznie w godzinach pracy
            Biura Programu. Szczegółowe informacje dotyczące danych teleadresowych Biura Programu
            zostaną wskazane na Stronie Internetowej Programu.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>REZYGNACJA Z PROGRAMU</strong>
        <FormRegulationsOList>
          <li>
            Uczestnik Programu jest uprawniony do rezygnacji z udziału w Programie w każdym czasie,
            poprzez przesłanie oświadczenia o takiej rezygnacji na adres email Koordynatora Programu
            przez osobę uprawnioną do jego reprezentowania Uczestnika.
          </li>
          <li>
            Opiekun lub Wychowawca Świetlicy jest uprawniony do rezygnacji z udziału odpowiednio
            Klasy lub Świetlicy w Programie w każdym czasie poprzez przesłanie oświadczenia o takiej
            rezygnacji na adres email Organizatora wraz z załączoną zgodą Uczestnika Programu na jego
            rezygnację z uczestnictwa w Programie. Rezygnacja Opiekuna jest skuteczna wyłącznie w
            stosunku do Klasy lub Świetlicy, którą się opiekuje i nie pociąga za sobą rezygnacji Uczestnika
            Programu (tj. Szkoły, która bierze udział w Programie poprzez pozostałe Klasy) w przypadku,
            gdy więcej niż jedna Klasa bierze udział w Programie.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>REKLAMACJE</strong>
        <FormRegulationsOList>
          <li>
            Organizator rozpatruje reklamacje związane z przebiegiem Programu w terminie 14 dni od dnia
            jej otrzymania.
          </li>
          <li>
            Reklamacje dotyczące przebiegu Programu należy zgłaszać w czasie trwania Programu lub w
            terminie 30 dni od daty zakończenia Programu.
          </li>
          <li>
            Reklamacje mogą być zgłaszane w formie pisemnej - listem na adres Biura Programu wraz z
            dopiskiem na kopercie „Reklamacja – Program Misja Kieszonkowe” lub mailem na adres
            kontakt@misjakieszonkowe.pl z tytułem maila „Reklamacja – Program Misja Kieszonkowe”.
            Dopiski na kopercie i w tytule maila nie są warunkiem złożenia reklamacji, ale pomagają
            przyspieszyć jej rozpoznanie.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>ZASADY PRZETWARZANIA DANYCH OSOBOWYCH</strong>
        <FormRegulationsOList>
          <li>
            Administratorem danych osobowych w ramach Programu jest Organizator, tj. BNP Paribas
            Bank Polska S.A. z siedzibą w Warszawie (01-211), przy ul. Kasprzaka 2, wpisany do rejestru
            przedsiębiorców prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII
            Wydział Gospodarczy Krajowego Rejestru Sądowego pod nr KRS: 0000011571, NIP:
            5261008546, o kapitale zakładowym w wysokości: 147 676 946,00zł, w całości wpłacony.
          </li>
          <li>
            Administrator danych osobowych wyznaczył Inspektora Ochrony Danych, z którym można się
            skontaktować pisemnie na adres korespondencyjny Organizatora wskazany w ust. 1 niniejszego
            paragrafu lub poprzez email: iodo@bnpparibas.pl.
          </li>
          <li>
            Organizator przetwarza dane osobowe następujących podmiotów danych: osób, które dokonały
            zgłoszenia Szkoły do Programu za pośrednictwem Formularza Kontaktowego, Opiekunów,
            Wychowawców Świetlicy oraz osób, które kontaktowały się w sprawie Programu, osób, które
            zgłosiły reklamacje, o których mowa w pkt 6 Regulaminu.
          </li>
          <li>
            Dane osobowe przetwarzane są w następujących celach:
            <FormRegulationsOList>
              <li>
                zgłoszenie do Programu i przeprowadzenia Programu i udział Szkół oraz uczestnictwo
                Klas i Świetlic w Programie, w tym realizacja i obsługa oraz przeprowadzenie
                Programu Misja Kieszonkowe (art. 6 ust. 1 lit. f RODO);
              </li>
              <li>
                dochodzenia lub ochrony przed ewentualnymi roszczeniami Szkół lub innych
                podmiotów i osób z tytułu realizacji Programu (art. 6 ust. 1 lit. f RODO);
              </li>
              <li>
                - rozpatrzenia reklamacji i udzielenia odpowiedzi na reklamacje (art. 6 ust. 1 lit. f
                RODO);
              </li>
              <li>
                realizacji ciążących na Organizatorze zobowiązań wynikających z powszechnie
                obowiązujących przepisów prawa (art. 6 ust. 1 lit. c RODO).
              </li>
            </FormRegulationsOList>
          </li>
          <li>
            Na potrzeby Programu Organizator jako administrator danych osobowych przetwarza
            następujące dane osobowe: imię i nazwisko osoby, która zgłosiła udział Szkoły w Programie,
            Opiekuna Klasy lub Wychowawcy Świetlicy oraz osoby kontaktującej się w sprawie Programu,
            adres e-mail, numer telefonu kontaktowego. Na potrzeby rozpatrzenia reklamacji Organizator
            przetwarza następujące dane osobowe: imię i nazwisko, adres korespondencyjny lub adres
            e-mail, wszystkie inne dane osobowe, jakie osoba składająca reklamację zdecyduje się podać
            w reklamacji.
          </li>
          <li>
            Dane osobowe będą przekazywane podmiotom działającym na rzecz i zlecenie Administratora,
            w szczególności Koordynatorowi Programu, jak również innym podmiotom, np. dostawcom
            usług teleinformatycznych oraz świadczącym usługi w zakresie wsparcia informatycznego i
            obsługi prawnej.
          </li>
          <li>
            Dane osobowe ww. osób będę przetwarzane przez Bank jako administratora danych osobowych
            przez okres przedawnienia roszczeń w zakresie Programu, tj. 6 lat (art. 118 k.c.) od zakończenia
            Programu albo od dnia rezygnacji z udziału w Programie. Niniejszy punkt dotyczy również
            danych osobowych Opiekunów lub Wychowawców Świetlicy, w przypadku, gdy rezygnują z
            uczestnictwa w Programie. Po upływie okresu wskazanego w zdaniu pierwszym Administrator
            usunie dane osobowe.
          </li>
          <li>
            Dane osób zgłaszających reklamację zostaną usunięte po upływie terminu przedawnienia
            roszczeń.
          </li>
          <li>
            Podmiotom danych przysługuje prawo żądania dostępu do ich danych osobowych oraz
            uzyskania ich kopii, ich poprawiania lub uzupełnienia, jeśli są nieprawidłowe lub niekompletne,
            żądania ich usunięcia, ograniczenia przetwarzania, przenoszenia danych.
          </li>
          <li>
            Podmiotom danych przysługuje również prawo do cofnięcia zgody (wycofanie zgody nie
            wpływa na poprawność przetwarzania przed jej wycofaniem), gdy przetwarzanie danych
            odbywa się na podstawie takiej zgody, oraz prawo do sprzeciwu wobec przetwarzania ich
            danych osobowych z przyczyn związanych z ich szczególną sytuacją, w przypadku
            przetwarzania danych na podstawie uzasadnionego interesu administratora.
          </li>
          <li>
            W celu realizacji praw, o których mowa w 8.9 i 8.10 należy skierować mail na adres
            justyna.pietrewicz@bnpparibas.pl.
          </li>
          <li>
            Podmiotom danych przysługuje prawo wniesienia skargi do organu nadzorczego – Prezesa
            Urzędu Ochrony Danych Osobowych (adres: ul. Stawki 2, 00 - 193 Warszawa).
          </li>
          <li>
            Podanie danych przez osobę zgłaszającą Szkołę do udziału w Programie i uprawnioną do
            reprezentowania Szkoły jest dobrowolne, przy czym jest warunkiem koniecznym do wzięcia
            udziału przez Szkołę w Programie. Niepodanie danych będzie skutkować brakiem możliwości
            uczestnictwa Szkoły w Programie. Niepodanie danych przez osobę składającą reklamację
            będzie skutkować brakiem możliwości rozpatrzenia reklamacji przez Organizatora.
          </li>
          <li>
            Administrator danych osobowych nie będzie przekazywać danych osobowych odbiorcom spoza
            Europejskiego Obszaru Gospodarczego lub organizacjom międzynarodowym w rozumieniu
            RODO. Administrator danych osobowych nie będzie podejmował zautomatyzowanych decyzji,
            w tym nie będzie dokonywał profilowania.
          </li>
        </FormRegulationsOList>
      </li>
      <li>
        <strong>POSTANOWIENIA KOŃCOWE</strong>
        <FormRegulationsOList>
          <li>
            W sprawach nieobjętych postanowieniami Regulaminu Programu stosuje się powszechnie
            obowiązujące przepisy prawa.
          </li>
          <li>
            Organizator zastrzega sobie prawo do zmiany postanowień Regulaminu Programu z ważnych
            przyczyn. Za ważne przyczyny uznać należy zmianę firmy, formy prawnej lub siedziby
            Organizatora lub Koordynatora Programu, a ponadto zmiany techniczne, związane z
            prawidłowym funkcjonowaniem Strony Internetowej Programu, konieczność dostosowania
            Regulaminu Programu ze względu na zmianę lub wejście w życie nowych bezwzględnie
            obowiązujących przepisów oraz wprowadzenie środków bezpieczeństwa, koniecznych w celu
            zapewnienia bezpieczeństwa osób małoletnich, a także w celu zapewnienia prawidłowego
            przebiegu Programu.
          </li>
          <li>
            Organizator informuje Uczestników Programu o zmianach Regulaminu Programu, najpóźniej
            na 7 dni przed jego zmianą, poprzez publikację na Stronie Internetowej Programu wyraźnej
            informacji o zmianie. Organizator poinformuje Uczestników Programu o zmianie Regulaminu
            Programu również za pośrednictwem poczty elektronicznej. Uczestnicy mogą nie zaakceptować
            zmian, informując o tym Organizatora najpóźniej do dnia poprzedzającego dzień wprowadzenia
            zmian, co spowoduje, że przestaną oni brać udział w Programie w dniu dostarczenia
            Organizatorowi oświadczenia o braku akceptacji wprowadzenia zmian.
          </li>
          <li>
            Niniejszy Regulamin Programu wchodzi w życie z dniem 27 października 2022 r.
          </li>
        </FormRegulationsOList>
      </li>
    </FormRegulationsOList>
  </>
)
