import axios from 'axios'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { Link } from 'gatsby'
import isEmail from 'is-email'
import React from 'react'
import { useEffect, useState } from 'react'
import { IdProvider } from 'react-use-id-hook'
import { useForm } from 'react-hook-form'
import styled, { css, keyframes } from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import Layout from '../../../components/layout'
import SEO from '../../../components/seo'
import PopoverInfo from '../../../components/forms/popover-info'
import ModalConfratulate from '../../../components/forms/modal-congratulate'
import { Input } from '../../../components/forms/input'
import {
  RegionSelect,
  CitySelect,
  GenericSelect,
  SchoolSelect,
} from '../../../components/forms/select'
import {
  PageInnerWrapper,
  Title,
  SubTitle,
  Button,
  Form,
  FormItemWrapper,
  FormBottomWrapper,
  CheckboxWrapper,
  NotesWrapper,
  NotesIcon,
  FormElementWrapper,
  FormPromptWrapper,
  FormPrompt,
} from '../../../components/elements/forms'
import { Step2Form } from '../../../components/forms/class/step2'
import { Step3Form } from '../../../components/forms/class/step3'
import { FormPromptinnerWrapper } from '../../../components/elements/FormPromptinnerWrapper'
import Back from '../../../components/elements/back'
import Contact from '../../../components/teachers/contact'

const SubmitClassPageInnerWrapper = styled(PageInnerWrapper)``

const SubmitTitle = styled(Title)``

const SubmitSubTitle = styled(SubTitle)``

const submitButtonLoadingAnimation = keyframes`
  0% {
    right: 35px;
  }

  50% {
    right: 20px;
  }

  100% {
    right: 35px;
  }
`

const submitButtonLoadingAnimate = css`
  animation: ${submitButtonLoadingAnimation} 2s linear infinite;
`

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
})`
  &:before {
    ${(props) => props.disabled && submitButtonLoadingAnimate}
  }
`

const SubmitForm = styled(Form)``

const SubmitFormItemWrapper = styled(FormItemWrapper)``

const SubmitFormBottomWrapper = styled(FormBottomWrapper)``

export const SubmitCheckboxWrapper = styled(CheckboxWrapper)`
  small {
    display: inline-block;
    line-height: 1.1em;

    a {
      color: ${(props) => props.theme.colorGreenLight};
    }
  }
`

const SubmitNotesWrapper = styled(NotesWrapper)`
  a {
    color: ${(props) => props.theme.colorGreenLight};
  }
`

const SubmitNotesIcon = styled(NotesIcon)``

const SubmitClassPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [city, setCity] = useState()
  const [region, setRegion] = useState()
  const [disableClassDetails, setDisableClassDetails] = useState(false)
  const [activeFocusID, setActiveFocusID] = useState('')
  const [values, setValues] = useState({})
  const [showPolicy1Modal, setShowPolicy1Modal] = useState(false)
  const [showPolicy2Modal, setShowPolicy2Modal] = useState(false)
  const [showThanksModal, setShowThanksModal] = useState(false)

  const classLevelOptions = [
    { value: 'świetlica', label: 'świetlica' },
    { value: '1', label: 'I' },
    { value: '2', label: 'II' },
    { value: '3', label: 'III' },
    { value: '4-6', label: 'IV-VI' },
  ]

  const classNameOptions = [...Array(26)]
    .map((q, w) => String.fromCharCode(w + 97))
    .map((v) => ({ value: v.toUpperCase(), label: v.toUpperCase() }))

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  })
  const watchCity = watch('city', null)
  const watchRegion = watch('region', null)
  const watchClassLevel = watch('classLevel', null);

  const onSubmitStep1 = (v) => {
    setValues(v)
    setShowPolicy1Modal(true)
  }

  const onSubmitStep2 = (v) => {
    setValues({ ...values, ...v })
    setShowPolicy1Modal(false)
    setShowPolicy2Modal(true)
  }

  const onSubmit = (v) => {
    setIsLoading(true)
    const data = {
      teacherName: values.teacherName,
      region: values.region.value,
      city: values.city.value,
      facilityName: values.facilityName.label,
      facilityId: values.facilityName.value,
      teacherPhone: values.teacherPhone,
      teacherEmail: values.teacherEmail,
      classLevel: values.classLevel.value,
      className: typeof values.className !== 'undefined' ? values.className.value : null,
      numberOfKids: typeof values.numberOfKids !== 'undefined' ? values.numberOfKids.value : null,
      tos1: values.tos1,
      tos2: values.tos2,
      tos3: values.tos3,
      tos4: v.tos4,
    }
    axios
      .post(process.env.GATSBY_MK_API_SUBMIT_CLASS_URL, data)
      .then((resp) => {
        setIsLoading(false)
        setShowPolicy1Modal(false)
        setShowPolicy2Modal(false)
        setShowThanksModal(true)
        clearAllBodyScrollLocks()
        reset()
      })
      .catch((err) => {
        if (err.response.status === 400) {
          alert(
            'Nie można przesłać zgłoszenia. Sprawdź czy adres e-mail nie został już użyty do rejestracji.'
          )
        } else {
          alert(
            'Przepraszamy, wystąpił błąd. Prosimy spróbować ponownie później.'
          )
        }

        setIsLoading(false)
        setShowPolicy1Modal(false)
        setShowPolicy2Modal(false)
        clearAllBodyScrollLocks()
        reset()
      })
  }

  const handlerFocus = (e) => {
    if (e.currentTarget !== e.target) {
      setActiveFocusID(e.currentTarget.id)
    }
  }

  const handlerBlur = (e) => {
    if (e.currentTarget !== e.target) {
      setActiveFocusID('')
    }
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setActiveFocusID('')
    }
  }

  useEffect(() => {
    setValue('facilityName', null)

    if (!watchCity) {
      setCity(null)
    } else {
      setCity(watchCity.value)
    }
  }, [watchCity])

  useEffect(() => {
    setValue('city', null)
    setValue('facilityName', null)

    if (!watchRegion) {
      setRegion(null)
    } else {
      setRegion(watchRegion.value)
    }
  }, [watchRegion])

  useEffect(() => {
    if (!watchClassLevel) {
      setDisableClassDetails(false);
    } else if (watchClassLevel && watchClassLevel.value === 'świetlica') {
      setDisableClassDetails(true);
    } else {
      setDisableClassDetails(false);
    }
  }, [watchClassLevel])

  return (
    <IdProvider>
      <SEO title="Zgłoszenie klasy do programu - Strefa nauczyciela" />
      <Layout>
        {showThanksModal && (
          <ModalConfratulate
            text={
              <>
                <p>
                  Zarejestrowaliśmy Twoje zgłoszenie do{'\u00A0'}programu Misja
                  Kieszonkowe.
                </p>
                <p>
                  <strong>
                    Na{'\u00A0'}podany adres e-mail wysłaliśmy link aktywacyjny,
                    kliknij w{'\u00A0'}niego aby potwierdzić rejestrację konta i
                    {'\u00A0'}otrzymać materiały dydaktyczne.
                  </strong>
                </p>
              </>
            }
            link="/strefa-nauczyciela/konkurs"
            textLink="Wróć na stronę programu"
          />
        )}
        <SubmitClassPageInnerWrapper>
          <Container>
            <Back
              link="/strefa-nauczyciela/"
              name="Powrót do strefy nauczyciela"
            />
            <Row>
              <Col>
                <SubmitTitle>Zgłoszenie klasy do{'\u00A0'}programu</SubmitTitle>
                <SubmitSubTitle>
                  * Wszystkie pola są obowiązkowe do{'\u00A0'}prawidłowego
                  zgłoszenia do{'\u00A0'}programu
                </SubmitSubTitle>
              </Col>
            </Row>
            <SubmitForm onSubmit={handleSubmit(onSubmitStep1)}>
              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="teacherName"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <Input
                        {...register('teacherName', { required: true })}
                        control={control}
                        label="Imię i nazwisko nauczyciela"
                        error={errors.teacherName}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="teacherName"
                      className="first"
                    >
                      <FormPrompt>
                        Podaj swoje imię i{'\u00A0'}nazwisko (nauczyciel
                        zgłaszający szkołę do{'\u00A0'}programu).
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="teacherName"
                  text={`Podaj swoje imię i nazwisko (nauczyciel zgłaszający szkołę do programu)`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="region"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <RegionSelect
                        {...register('region', { required: true })}
                        control={control}
                        label="Województwo (w którym znajduje się placówka)"
                        error={errors.region}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="region"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy województwo, w{'\u00A0'}którym
                        znajduje się{'\u00A0'}placówka.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="region"
                  text={`Wybierz z listy województwo, w którym znajduje się placówka`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="city"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <CitySelect
                        {...register('city', { required: true })}
                        control={control}
                        region={region}
                        label="Miejscowość (w której znajduje się placówka)"
                        error={errors.city}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="city"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy miejscowość, w{'\u00A0'}której
                        znajduje się{'\u00A0'}placówka.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="city"
                  text={`Wybierz z listy miejscowość, w której znajduje się placówka`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="facilityName"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <SchoolSelect
                        {...register('facilityName', { required: true })}
                        control={control}
                        region={region}
                        city={city}
                        label="Nazwa placówki"
                        error={errors.facilityName}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="facilityName"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy nazwę placówki.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="facility"
                  text={`Wybierz z listy nazwę placówki. Jeśli nie możesz znaleźć odpowiedniej placówki na liście kliknij w link poniżej.`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="teacherPhone"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                      className="phone"
                    >
                      <Input
                        {...register('teacherPhone', {
                          required: true,
                          pattern: /\d{9,12}/,
                        })}
                        control={control}
                        label="Telefon nauczyciela zgłaszającego"
                        error={errors.teacherPhone}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="teacherPhone"
                    >
                      <FormPrompt>Podaj swój numer telefonu.</FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="teacherPhone"
                  text={`Podaj swój numer telefonu`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="teacherEmail"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <Input
                        {...register('teacherEmail', {
                          required: true,
                          validate: (value) => isEmail(value),
                        })}
                        control={control}
                        label="E-mail nauczyciela zgłaszającego"
                        error={errors.teacherEmail}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="teacherEmail"
                    >
                      <FormPrompt>
                        Podaj swój adres e-mail, na{'\u00A0'}który wyślemy
                        potwierdzenie zgłoszenia.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="teacherEmail"
                  text={`Podaj swój adres email na który wyślemy potwierdzenie zgłoszenia`}
                />
              </SubmitFormItemWrapper>

              <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={6}>
                    <Row>
                      <Col xs={6}>
                        <FormElementWrapper
                          active={activeFocusID}
                          id="class"
                          onFocus={handlerFocus}
                          onBlur={handlerBlur}
                          className="class level"
                        >
                          <GenericSelect
                            {...register('classLevel', {
                              required: true,
                              pattern: /[3|4\-6]/,
                            })}
                            control={control}
                            options={classLevelOptions}
                            label="Poziom klasy"
                            error={errors.classLevel}
                          />
                        </FormElementWrapper>
                      </Col>
                      {!disableClassDetails && <>
                        <Col xs={6}>
                          <FormElementWrapper
                            active={activeFocusID}
                            id="class"
                            className="tranparent class name"
                            onFocus={handlerFocus}
                            onBlur={handlerBlur}
                          >
                            <GenericSelect
                              {...register('className', { required: true })}
                              control={control}
                              options={classNameOptions}
                              label="Nazwa klasy"
                              error={errors.className}
                              disabled={disableClassDetails}
                            />
                          </FormElementWrapper>
                        </Col>
                      </>}
                    </Row>
                  </Col>
                  <FormPromptWrapper xs={12} md={6}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="class"
                    >
                      <FormPrompt>
                        Wybierz z{'\u00A0'}listy poziom{!disableClassDetails && <> i{'\u00A0'}nazwę</>} klasy.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="class"
                  text={`Wybierz z listy poziom i nazwę klasy`}
                />
              </SubmitFormItemWrapper>

              {!disableClassDetails && <SubmitFormItemWrapper xs={12}>
                <Row>
                  <Col xs={12} lg={3}>
                    <FormElementWrapper
                      active={activeFocusID}
                      id="numberOfKids"
                      onFocus={handlerFocus}
                      onBlur={handlerBlur}
                    >
                      <Input
                        {...register('numberOfKids', {
                          required: true,
                          validate: (value) => {
                            value = parseInt(value, 10)
                            if (value >= 1 && value <= 50) return true
                            return false
                          },
                        })}
                        control={control}
                        label="Liczba dzieci w klasie"
                        error={errors.numberOfKids}
                        type="number"
                        min="0"
                        max="50"
                        disabled={disableClassDetails}
                      />
                    </FormElementWrapper>
                  </Col>
                  <FormPromptWrapper xs={12} sm={{ size: 6, offset: 3 }}>
                    <FormPromptinnerWrapper
                      active={activeFocusID}
                      current="numberOfKids"
                      className="last"
                    >
                      <FormPrompt>
                        Podaj liczbę dzieci w{'\u00A0'}klasie.
                      </FormPrompt>
                    </FormPromptinnerWrapper>
                  </FormPromptWrapper>
                </Row>
                <PopoverInfo
                  idName="numberOfKids"
                  text={`Podaj liczbę dzieci w klasie`}
                />
              </SubmitFormItemWrapper>}
              <SubmitFormBottomWrapper xs={12}>
                <Row>
                  <Col xs={12} md={6}>
                    <Row>
                      <SubmitCheckboxWrapper xs={12}>
                        <SubmitButton
                          as="button"
                          className="btn"
                          disabled={showPolicy1Modal || showPolicy2Modal}
                        >
                          Dalej
                        </SubmitButton>
                      </SubmitCheckboxWrapper>
                      <SubmitNotesWrapper xs={12}>
                        <p>
                          Informujemy, że administratorem Pani/Pana danych jest
                          BNP Paribas Bank Polska S.A. z siedzibą w Warszawie
                          (01-211), przy ulicy Kasprzaka 2.
                          <br />
                          Przetwarzane dane są w celu obsługi zgłoszeń do
                          Programu, przeprowadzenia Programu, udziału Szkoły
                          oraz uczestnictwa Klasy w Programie. Ma Pani/Pan prawo
                          do wycofania zgody (gdy przetwarzanie danych odbywa
                          się na podstawie takiej zgody), dostępu do danych oraz
                          ich sprostowania, usunięcia, sprzeciwu wobec
                          przetwarzania i przenoszenia. Ma Pan/Pani również
                          prawo do wniesienia sprzeciwu wobec przetwarzania
                          danych oraz wniesienia skargi do Urzędu Ochrony Danych
                          Osobowych.{' '}
                          <Link
                            href="/docs/Ochrona danych osobowych.pdf"
                            target="_blank"
                          >
                            Kliknij i dowiedz się więcej o ochronie danych.
                          </Link>
                          .
                        </p>
                      </SubmitNotesWrapper>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <Row>
                      <SubmitNotesWrapper xs={12}>
                        {/* <SubmitNotesIcon />
                        <p>
                          Część danych szkół zawartych na{'\u00A0'}stronie
                          została uzyskana ze{'\u00A0'}strony Ministerstwa
                          Edukacji Narodowej, z{'\u00A0'}Rejestru Szkół i
                          {'\u00A0'}Placówek Oświatowych:
                          https://rspo.men.gov.pl/, a{'\u00A0'}następnie
                          zaktualizowana przez Garden of{'\u00A0'}Words
                          Pawlikowska Poleszak i{'\u00A0'}Nieściuszko-Bujnicka
                          spółka jawna z{'\u00A0'}siedzibą w{'\u00A0'}Warszawie,
                          ul.{'\u00A0'}Zawojska{'\u00A0'}36, 02-927 Warszawa.
                          Dane te{'\u00A0'}udostępniane są{'\u00A0'}na{'\u00A0'}
                          licencji Creative Commons Uznanie Autorstwa 3.0 Polska
                          (CC BY 3.0 PL), o{'\u00A0'}ile nie{'\u00A0'}jest to
                          {'\u00A0'}stwierdzone inaczej.
                        </p> */}
                      </SubmitNotesWrapper>
                    </Row>
                  </Col>
                </Row>
              </SubmitFormBottomWrapper>
            </SubmitForm>

            {showPolicy1Modal && <Step2Form onSubmit={onSubmitStep2} />}
            {showPolicy2Modal && (
              <Step3Form isLoading={isLoading} onSubmit={onSubmit} />
            )}
          </Container>
        </SubmitClassPageInnerWrapper>
        <Contact />
      </Layout>
    </IdProvider>
  )
}

export default SubmitClassPage
