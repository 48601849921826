import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { Modal, ModalBody } from 'reactstrap'

import { ButtonLink } from '../elements'

const ModalConfratulateWrapper = styled(Modal)`
  .modal-content {
    position: relative;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 1.2;
    padding-top: 20px;
    padding-bottom: 30px;
    border-top: 20px solid ${(props) => props.theme.colorGreenBright};
    border-radius: 0;
    z-index: 10000;
    @media (min-width: 992px) {
      padding-left: 8.5%;
      padding-right: 8.5%;
    }
    @media (min-width: 576px) and (max-width: 991px) {
      padding-left: 5%;
      padding-right: 5%;
    }
    @media (max-width: 575px) {
      font-size: 17px;
      padding-left: 2.5%;
      padding-right: 2.5%;
    }
    p {
      margin-bottom: 1.2rem;
    }
  }
`

const ModalConfratulateTitle = styled.h2`
  color: ${(props) => props.theme.colorGreenLight};
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 30px;
  @media (max-width: 575px) {
    font-size: 40px;
  }
`

const ModalConfratulateButtonLink = styled(ButtonLink)`
  margin-top: 20px;
  @media (max-width: 575px) {
    font-size: 22px;
    padding-left: 55px;
    padding-right: 55px;
  }
  @media (max-width: 340px) {
    font-size: 18px;
    padding-left: 50px;
    padding-right: 50px;
  }
`

const ModalConfratulate = (props) => {
  const { title, text, link, textLink, className } = props

  const [modal, setModal] = useState(true)

  const toggle = () => setModal(!modal)

  return (
    <ModalConfratulateWrapper
      isOpen={modal}
      toggle={toggle}
      className={className}
      centered
    >
      <ModalBody>
        <ModalConfratulateTitle>
          {title ? title : `Gratulujemy!`}
        </ModalConfratulateTitle>
        {text}
        <ModalConfratulateButtonLink
          as={Link}
          to={link}
          className="btn"
          title={textLink}
        >
          {textLink}
        </ModalConfratulateButtonLink>
      </ModalBody>
    </ModalConfratulateWrapper>
  )
}

export default ModalConfratulate
